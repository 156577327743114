/**
 * Mixin for data utils on data entry screens
 *  TODO: The methods are currently limited to updating hardcoded fields.
 *      A means of addressing the fields throguh variables is preferred.
 */
export default {

    data: ()=>({

        // Pad a the water right field with the desired number of a selected character.
        pad(nbr, amt, token) {
            if (!nbr) return;
            this.model.search.wr_nbr = nbr.padStart(amt, token);
        },
    })

}