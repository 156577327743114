<template>

    <v-dialog v-model="getHelp" inset max-width="600px">
        <v-card class="text-center">
            <v-card-title class="headline">
                <pdf  src="./static/help_header.pdf" style="overflow: auto"></pdf>
            </v-card-title>
            <div>
                <slot></slot>
            </div>
            <v-btn class="mt-6" text
                   color="error"
                   @click="$emit('toggle-help')">
                close
            </v-btn>
        </v-card>
    </v-dialog>
</template>

<script>

    import pdf from 'vue-pdf';

    export default {

        name: 'Help-Component',

        props: {

            help : {
                type: Boolean,
                default: false
            }
        },
        computed: {
            getHelp() {
                return this.help
            },
        },
        components : {
            pdf
        }
    }

</script>