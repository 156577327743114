<template>

    <v-dialog v-model="getShow" persistent max-width="600">
        <v-card>

            <v-alert class="mb-0 text-uppercase title" text prominent
                     :type="type"
                     :icon="icon">
                <slot name="title"></slot>
            </v-alert>

            <v-card-text>
                <slot name="body"></slot>
            </v-card-text>

            <v-card-actions>
                <slot name="actions">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" outlined @click="$emit('close-dialog')">Ok</v-btn>
                </slot>
            </v-card-actions>

        </v-card>
    </v-dialog>

</template>

<script>

    export default {

        name: 'Dialog-Component',

        props: {
            show: {
                type: Boolean,
                default: false
            },
            icon: {
                type: String,
                default: 'mdi-alert'
            },
            type: {
                type: String,
                default: 'error'
            }
        },
        computed: {
            getShow() {
                return this.show
            },
        },
    }

</script>