<template>
    <div class="caption grey--text text--darken-2 d-flex justify-space-between">
        <div>Selected {{ currSelected }} of {{ totalAllowed }} allowed</div>
        <div>Showing {{ start }} to {{ end }} of {{ total }} total</div>
    </div>
</template>

<script>

    export default {

        name: 'list-info',

        props: [
            'list',
            'selected',
            'multiple'
        ],
        
        computed: {

            start() {
                return this.list.length > 0 ? 1 : 0;
            },

            end() {
                return this.list.length;
            },

            total() {
                return this.list.length;
            },

            totalAllowed() {
                return this.multiple ? this.list.length : 1;
            },

            currSelected() {
                return Array.isArray(this.selected) ?
                    this.selected.length:
                    this.selected === undefined ?
                        0:
                        1;
            }
        },
    }

</script>