<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-footer padless :tile="isMobile"
              :fixed="isMobile"
              :elevation="isMobile ? 6 : 0"
              :class="isMobile ? 'mt-0' : 'mt-4' "
              :height="isMobile ? '64px' : '72px'">

        <v-card :outlined="!isMobile" class="fill-height width--full">
            <v-row no-gutters align="center" class="fill-height">
                <slot name="content">
                    <v-col cols="4" class="text-center">
                        <v-btn v-if="back"
                               outlined large
                               color="grey darken-1"
                               @click="$emit('step-back')">
                            <slot name="back">
                                <v-icon medium>mdi-chevron-left</v-icon>
                                <span>Back</span>
                            </slot>
                        </v-btn>
                    </v-col>

                    <v-col cols="4" class="text-center">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" v-if="help"
                                       color="grey darken-1" outlined large
                                       @click="$emit('toggle-help')">
                                    <v-icon small>mdi-help</v-icon>
                                </v-btn>
                            </template>
                            <span>help with this step</span>
                        </v-tooltip>
                    </v-col>

                    <v-col cols="4" class="text-center">
                        <v-btn v-if="next"
                               :disabled="disableNext"
                               :loading="isLoading"
                               color="primary" large
                               type="submit">
                            <slot name="next">
                                <span>Next</span>
                                <v-icon medium>mdi-chevron-right</v-icon>
                            </slot>
                        </v-btn>
                    </v-col>
                </slot>
            </v-row>
        </v-card>

    </v-footer>

</template>

<script>

    export default {

        name: 'Footer-Component',

        props: {
            back: {
                type: Boolean,
                default: true
            },
            next: {
                default: true
            },
            help: {
                type: Boolean,
                default: true
            },
            disableNext: {
                type: Boolean,
                default: false
            },
            isLoading: {
                type: Boolean,
                default: false
            }
        },

        computed: {

            isMobile() {
                return this.$vuetify.breakpoint.xsOnly
            }
        },
    }

</script>