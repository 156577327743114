/**
 * Mixin for static content
 *  Districts Map
 */
export default {

    data: () => ({

        districts: [
            {value: '1', text: 'Albuquerque (District 1)'},
            {value: '2', text: 'Roswell (District 2)'},
            {value: '3', text: 'Deming (District 3)'},
            {value: '4', text: 'Las Cruces (District 4)'},
            {value: '5', text: 'Aztec (District 5)'},
            {value: '6', text: 'Santa Fe (District 6)'},
            {value: '7', text: 'Cimarron (District 7)'},
        ]
    })
}