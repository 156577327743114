<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">

    <v-form @submit.prevent="stepForward" ref="form" v-model="isFormValid">

        <v-card outlined>

            <v-card-title>
                <div class="font-weight-light display-1 width--full">
                    <span>
                        <div class="grey--text text--darken-3">{{ stepper.step }}. Select your meter
                            <span class="right_span">
                                <!--meter not show or replaced button for medium and up -->
                                <v-btn v-show="$vuetify.breakpoint.mdAndUp" x-small text color="primary"
                                       @click.stop="findMeterHelp = true,forceRerender()">
                                    If you don't see your meter or it was replaced
                                </v-btn>
                            </span>
                        </div>
                    </span>
                    <!--meter not show or replaced button for small and down -->
                    <v-row v-show="$vuetify.breakpoint.smAndDown" align="center">
                        <v-btn x-small text color="primary"
                               @click.stop="findMeterHelp = true,forceRerender()">
                            click if meter not shown or replaced
                        </v-btn>
                    </v-row>
                    <v-divider class="mt-1"></v-divider>
                    <info-component class="mt-1"
                                    :selected="selected"
                                    :list="model.pods.list">
                    </info-component>
                </div>
            </v-card-title>

            <v-card-text class="py-0">

                <v-container :class="{'pt-0': $vuetify.breakpoint.smAndDown, 'pt-0 overflow-y-auto desktop-meter-list': $vuetify.breakpoint.mdAndUp}">
                <v-item-group v-model="selected" max="1">
                    <v-container class="pt-0">
                        <v-row>
                            <v-col v-for="p in model.pods.list"
                                   :key="p.pod_rec_nbr"
                                   cols="12" sm="6" md="4">

                                <v-item v-slot:default="{ active, toggle }">
                                    <meter-detail-component :model="model"
                                                            :meter="p.meter"
                                                            :active="active"
                                                            :toggle="toggle">
                                    </meter-detail-component>
                                </v-item>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-item-group>
                </v-container>
            </v-card-text>
        </v-card>

        <!--        Standard help component-->
        <help-component :help="showHelpDrawer"
                        @toggle-help="showHelpDrawer=!showHelpDrawer">
            <v-card>
                <pdf  src="./static/help_select_pg2.pdf" style="max-height: 400px; width: 100%; overflow: auto"></pdf>
            </v-card>
        </help-component>

        <!--   Special help for those who cannot find their meters or replaced them     -->
        <help-component :help= "findMeterHelp"
                        @toggle-help="findMeterHelp=!findMeterHelp">
            <v-card>
                <pdf  src="./static/help_find_meter.pdf" style="max-height: 400px; width: 100%; overflow: auto"></pdf>
            </v-card>
        </help-component>

        <footer-component :class="{'v-card--loading, v-card--disabled': isLoading}"
                          :disableNext="!hasSelection"
                          @step-back="stepBack"
                          @toggle-help="showHelpDrawer=!showHelpDrawer">
        </footer-component>

    </v-form>

</template>

<script>

    import InfoComponent from './InfoComponent';
    import FooterComponent from "./FooterComponent";
    import HelpComponent from './HelpComponent';
    import MeterDetailComponent from './MeterDetailComponent';
    import pdf from 'vue-pdf';

    export default {

        name: 'Meter-List',

        components: {
            InfoComponent,
            FooterComponent,
            HelpComponent,
            MeterDetailComponent,
            pdf
        },

        $_veeValidate: {
            validator: 'new'
        },

        props: {
            model: {
                type: Object,
                required: true
            },
            stepper: {
                type: Object,
                required: true
            }
        },

        computed: {
            hasSelection() {
                return this.selected === 0 ||
                    this.selected > 0 ||
                    this.selected && this.selected.length > 0;
            },
            isMdOrLess() {
                return this.$vuetify.breakpoint.mdAndDown
            }
        },

        data: () => ({

            selected: [],
            isFormValid: false,
            isLoading: false,
            showHelpDrawer: false,
            active: false,
            ripple: false,
            findMeterHelp: false,

            info: {
                start: 0,
                end: 0,
                total: 0,
                selected: 0
            },
        }),

        methods: {

            forceRerender() {
                this.pdfKey += 1;
            },

            stepForward() {
                this.$validator.validateAll().then(isValid => {

                    if (isValid) {
                        let localStepper = this.stepper;
                        let localModel = this.model;
                        localStepper.validators[this.stepper.step] = this.$validator;
                        localModel.pods.selected = this.model.pods.list[this.selected];
                        localModel.meter.selected = this.model.pods.list[this.selected].meter;
                        localStepper.step++;
                        this.$emit("updateModel", localModel);
                        this.$emit("updateStep", localStepper);

                    }
                });
            },

            stepBack() {
                let localStepper = this.stepper;
                localStepper.step--
                this.$emit("updateStep", localStepper);
            },
        }
    }

</script>
