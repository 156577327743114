/**
 * Mixin for static content
 *  Basins Map
 */

export default {

    data: () => ({
        basins: [
            {value: 'A', text: 'A(Animas)'},
            {value: 'B', text: 'B(Bluewater)'},
            {value: 'C', text: 'C(Carlsbad)'},
            {value: 'CC', text: 'CC(Curry)'},
            {value: 'CD', text: 'CD(Cloverdale)'},
            {value: 'CL', text: 'CL(Causey Lingo)'},
            {value: 'CP', text: 'CP(Capitan)'},
            {value: 'CR', text: 'CR(Canadian River)'},
            {value: 'CT', text: 'CT(Clayton)'},
            {value: 'E', text: 'E(Estancia)'},
            {value: 'FS', text: 'FS(Fort Sumner)'},
            {value: 'G', text: 'G(Gallup)'},
            {value: 'GSF', text: 'GSF(Gila-San Francisco)'},
            {value: 'H', text: 'H(Hondo)'},
            {value: 'HA', text: 'HA(HACHITA)'},
            {value: 'HC', text: 'HC(HAGERMAN CANAL)'},
            {value: 'HS', text: 'HS(Hot Springs)'},
            {value: 'HU', text: 'HU(Hueco)'},
            {value: 'L', text: 'L(Lea County)'},
            {value: 'LA', text: 'LA(Las Animas Creek)'},
            {value: 'LRG', text: 'LRG(Lower Rio Grande)'},
            {value: 'LV', text: 'LV(Lordsburg Valley)'},
            {value: 'LWD', text: 'LWD(Livestock Wtr Decl)'},
            {value: 'M', text: 'M(Mimbres)'},
            {value: 'MR', text: 'MR(MOUNT RILEY)'},
            {value: 'NH', text: 'NH(Nutt-Hockett)'},
            {value: 'P', text: 'P(Portales)'},
            {value: 'PL', text: 'PL(Playas)'},
            {value: 'PN', text: 'PN(Penasco)'},
            {value: 'RA', text: 'RA(Roswell Artesian)'},
            {value: 'RG', text: 'RG(Rio Grande)'},
            {value: 'S', text: 'S(Sandia)'},
            {value: 'SD', text: 'SD(Surface Declaration)'},
            {value: 'SJ', text: 'SJ(San Juan)'},
            {value: 'SP', text: 'SP(Surface Permit)'},
            {value: 'SS', text: 'SS(San Simon)'},
            {value: 'ST', text: 'ST(SALT BASIN)'},
            {value: 'T', text: 'T(Tularosa)'},
            {value: 'TU', text: 'TU(Tucumcari)'},
            {value: 'UP', text: 'UP(Upper Pecos)'},
            {value: 'VV', text: 'VV(Virden Valley)'},
            {value: 'Y', text: 'Y(YAQUI)'}
        ]
    })
};