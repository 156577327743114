<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">

    <v-form @submit.prevent="stepForward" ref="form" v-model="isFormValid">

        <v-card outlined :loading="isLoading">

            <v-card-title>
                <div class="font-weight-light display-1 width--full">
                    <div class="grey--text text--darken-3">{{ stepper.step }}. Enter your readings</div>
                    <v-divider class="mt-1"></v-divider>
                </div>
            </v-card-title>

            <v-card-text>
                <v-row>
                    <v-col cols="12" md="10" lg="8">
                        <reading-detail-component :model="model" :stepper="stepper"></reading-detail-component>
                    </v-col>
                </v-row>
                <small>*indicates required field</small>
            </v-card-text>
        </v-card>

        <help-component :help="showHelpDrawer"
                        @toggle-help="showHelpDrawer=!showHelpDrawer">

            <v-card>
                <pdf  src="./static/help_reading_pg3.pdf" style="max-height: 400px; width: 100%; overflow: auto"></pdf>
            </v-card>

        </help-component>

        <footer-component :class="{'v-card--loading, v-card--disabled': isLoading}"
                          :disableNext="disableNext"
                          @step-back="stepBack"
                          @toggle-help="showHelpDrawer=!showHelpDrawer">
        </footer-component>

    </v-form>

</template>

<script>

    import ReadingDetailComponent from "./ReadingDetailComponent";
    import FooterComponent from "./FooterComponent";
    import HelpComponent from "./HelpComponent";
    import pdf from 'vue-pdf';

    export default {

        name: 'Meter-Reading',

        $_veeValidate: {
            validator: 'new'
        },

        components: {
            ReadingDetailComponent,
            FooterComponent,
            HelpComponent,
            pdf
        },

        props: {
            model: {
                type: Object,
                required: true
            },
            stepper: {
                type: Object,
                required: true
            }
        },

        computed: {
            disableNext() {
                return !this.hasMeterReadings() || this.$validator.errors.items.length > 0
            }
        },

        data: () => ({
            isFormValid: false,
            isLoading: false,
            showHelpDrawer: false,
        }),

        methods: {
            hasMeterReadings() {
                return this.model.meter.selected.readings.length > 0;
            },

            stepForward() {
                this.$validator.validateAll().then(isValid => {

                    if (isValid && this.hasMeterReadings()) {
                        let localStepper = this.stepper;
                        localStepper.validators[this.stepper.step] = this.$validator;
                        localStepper.step++;
                        this.$emit("updateStep", localStepper);
                    }
                });
            },

            stepBack() {
                let localStepper = this.stepper;
                localStepper.step--;
                this.$emit("updateStep", localStepper);
            }
        },
    }

</script>
