<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">

    <v-form @submit.prevent="stepForward" ref="form" v-model="isFormValid">

        <v-card outlined :loading="isLoading">

            <v-card-title>
                <div class="font-weight-light display-1 width--full">
                    <div class="grey--text text--darken-3">{{ stepper.step }}. Begin your search</div>
                    <v-divider class="mt-1"></v-divider>
                </div>
            </v-card-title>

            <v-card-text>

                <v-container grid-list-xl>
                    <v-layout wrap>
                        <v-flex xs12 sm12 md12>
                            <v-radio-group v-model="model.search.filter_by" row>
                                <v-radio label="Well Tag Number" value="tag"></v-radio>
                                <v-radio label="Water Rights File Number" value="file"></v-radio>
                            </v-radio-group>
                        </v-flex>

                        <template v-if="model.search.filter_by === 'tag'">
                            <v-flex xs12 sm6 md4 xl3 class="py-0">
                                <v-text-field
                                        maxlength="5"
                                        key="well_tag_nbr"
                                        name="well_tag_nbr"
                                        class="v-label--offset"
                                        v-model="model.search.well_tag_nbr"
                                        label="Well Tag Number*"
                                        clearable
                                        outlined
                                        prepend-inner-icon="mdi-database-search"
                                        :counter="5"
                                        v-validate="'required|alpha_num|max:5'"
                                        :error-messages="errors.collect('well_tag_nbr')"
                                        data-vv-name="well_tag_nbr"
                                        data-vv-as="Well Tag Number"
                                        hint="Well Tag Number can be up to 5 digits">
                                </v-text-field>
                            </v-flex>
                        </template>

                        <template v-if="model.search.filter_by === 'file'">
                            <v-flex xs12 sm4 md4 class="py-0">
                                <v-select
                                        key="basin"
                                        name="basin"
                                        class="v-label--offset"
                                        v-model="model.search.basin"
                                        :items="basins"
                                        prepend-inner-icon="mdi-folder-search"
                                        :menu-props="{ bottom: true, offsetY: true }"
                                        outlined
                                        :error-messages="errors.collect('basin')"
                                        v-validate="'required'"
                                        data-vv-name="basin"
                                        data-vv-as="Basin"
                                        label="Basin*"></v-select>
                            </v-flex>

                            <!-- validate regex requires the format below-->
                            <v-flex xs12 sm4 md4 lg3 xl2 class="py-0">
                                <v-text-field
                                        maxlength="5"
                                        minlength="5"
                                        key="wr_nbr"
                                        name="wr_nbr"
                                        class="v-label--offset"
                                        v-model="model.search.wr_nbr"
                                        @blur="pad(model.search.wr_nbr, 5, '0')"
                                        @keydown.enter="pad(model.search.wr_nbr, 5, '0')"
                                        label="Number*"
                                        clearable outlined
                                        prepend-inner-icon="mdi-database-search"
                                        :counter="5"
                                        hint="Number can be up to 5 digits"
                                        :error-messages="errors.collect('wr_nbr')"
                                        v-validate="{ required: true, max: 5, regex:/^(?!0+$)[A-Za-z0-9][0-9]*$/ }"
                                        data-vv-name="wr_nbr"
                                        data-vv-as="Number">
                                </v-text-field>
                            </v-flex>

                            <v-flex xs12 sm4 md4 lg3 xl2 class="py-0">
                                <v-text-field
                                        key="suffix"
                                        name="suffix"
                                        class="v-label--offset"
                                        v-model="model.search.suffix"
                                        clearable outlined
                                        prepend-inner-icon="mdi-database-search">
                                    <template v-slot:label>
                                        <div>Suffix
                                            <small>(optional)</small>
                                        </div>
                                    </template>
                                </v-text-field>
                            </v-flex>

                        </template>

                    </v-layout>

                </v-container>
                <small>*indicates required field</small>
            </v-card-text>

        </v-card>

        <help-component :help="showHelpDrawer"
                        @toggle-help="showHelpDrawer = !showHelpDrawer">
            <pdf  src="./static/help_id_pg1.pdf" style="max-height: 400px; width: 100%; overflow: auto"></pdf>
        </help-component>

        <footer-component :class="{'v-card--loading, v-card--disabled': isLoading}"
                          :back='false'
                          :isLoading="isLoading"
                          :disableNext="!isFormValid"
                          @toggle-help="showHelpDrawer=!showHelpDrawer">

            <template slot="next">
                <span>Search</span>
                <v-icon medium>mdi-chevron-right</v-icon>
            </template>

        </footer-component>

        <dialog-component :show="showNotFoundDialog" @close-dialog="showNotFoundDialog = false">
            <template slot="title">Record Not Found</template>
            <template slot="body">
                Could not find any record associated with the
                <span class="font-weight-bold">
                    {{ model.search.filter_by === 'file' ? 'Water Rights File Number' : 'Well Tag Number' }}
                </span>
                provided. Please try again or contact your local office for further assistance.
            </template>
        </dialog-component>

        <dialog-component :show="showRequestErrorDialog" @close-dialog="showRequestErrorDialog = false">
            <template slot="title">Server Error</template>
            <template slot="body">Sorry, the search request failed at this time.<p/>
                Please try again. If the problem persists, please contact the OSE Meter Reading support.
            </template>
        </dialog-component>

    </v-form>

</template>

<script>

    import axios from 'axios';
    import basinsMixin from 'mixins/basins.mixin';
    import utilsMixin from 'mixins/utils.mixin';
    import FooterComponent from "./FooterComponent";
    import HelpComponent from './HelpComponent';
    import DialogComponent from "./DialogComponent";
    import pdf from 'vue-pdf';

    export default {

        "name": 'Meter-Search',

        "$_veeValidate": {
            "validator": 'new'
        },

        "mixins": [basinsMixin, utilsMixin],

        "components": {
            FooterComponent,
            HelpComponent,
            DialogComponent,
            pdf
        },

        "props": {

            "model": {
                "type": Object,
                "required": true
            },
            "stepper": {
                "type": Object,
                "required": true
            },
        },

        "data": () => ({

            "isFormValid": false,
            "isLoading": false,
            "showNotFoundDialog": false,
            "showRequestErrorDialog": false,
            "showHelpDrawer": false
        }),

        "methods": {
            //user submits search page.
            // URL is cobbled together, suffix and well_tag are capitalized, query is sent to MRAPI
            // model.pods.list is populated with response results
            "stepForward"() {
                this.$validator.validateAll().then(isValid => {

                    if (isValid) {
                        let localStepper = this.stepper;
                        this.isLoading = true;
                        localStepper.validators[this.stepper.step] = this.$validator;
                        this.clearModels();
                        //wr_nbr, suffix, and well_tag_nbr are uppercase strings in Waters DB
                        let url = this.model.search.filter_by === 'file' ?
                            `${process.env.VUE_APP_MR_API_URI}/waters/search?filter=${this.model.search.filter_by}&basin=${this.model.search.basin}&nbr=${this.model.search.wr_nbr.toUpperCase()}&suffix=${this.model.search.suffix.toUpperCase() || ''}` :
                            `${process.env.VUE_APP_MR_API_URI}/waters/search?filter=${this.model.search.filter_by}&well_tag=${this.model.search.well_tag_nbr.toUpperCase()}`;

                        axios.get(url)
                            .then(res => {
                                if (res.data.data) {
                                    this.model.pods.list = this.filterVirtualMeters(res.data.data);

                                    if (this.model.pods.list.length) {
                                        this.addMeterToPodList();
                                        localStepper.step++;
                                        this.$emit("updateStep", localStepper);
                                    } else {
                                        this.showNotFoundDialog = true;
                                    }
                                }
                                else if (res.data.error) {
                                    this.showRequestErrorDialog = true;
                                    console.log(res.data.error);
                                }
                            })
                            .catch(err => {
                                this.showRequestErrorDialog = true;
                                console.log(err);
                            })
                            .finally(() => this.isLoading = false)
                    }
                })
            },

            "clearModels"() {
                this.model.pods.list = [];
                this.model.pods.selected = {};
                this.model.meter.selected = { //todo: shallow copy a init object state
                    "readings": [],
                    "number_of_dials": 0,
                    "current_reading_date": new Date()
                };
            },

            "filterVirtualMeters"(list) {
                return list.filter(pod =>
                    pod.mtr_make == null || pod.mtr_make && !pod.mtr_make.toUpperCase().includes("VIRTUAL") );
            },

            "addMeterToPodList"() {

                for (let pod in this.model.pods.list) {

                    this.model.pods.list[pod].meter = {
                        "basin": this.model.pods.list[pod].basin,
                        "nbr": this.model.pods.list[pod].nbr,
                        "suffix": this.model.pods.list[pod].suffix,
                        "pod_basin": this.model.pods.list[pod].pod_basin,
                        "pod_nbr": this.model.pods.list[pod].pod_nbr,
                        "pod_suffix": this.model.pods.list[pod].pod_suffix,
                        "meter_rec_number": this.model.pods.list[pod].mtr_rec_nbr,
                        "meter_make": this.model.pods.list[pod].mtr_make,
                        "meter_serial_number": this.model.pods.list[pod].mtr_serial_nbr,
                        "current_reading": this.model.pods.list[pod].curr_reading,
                        "current_reading_date": this.model.pods.list[pod].curr_reading_date,
                        "waters_acct_year": this.model.pods.list[pod].curr_acct_year,
                        "number_of_dials": this.model.pods.list[pod].number_of_dials,
                        "unit_of_measure": this.model.pods.list[pod].unit_of_measure,
                        "well_tag": this.model.pods.list[pod].well_tag,
                        "readings": []
                    }
                }
            },
        },
    }

</script>

