<template>

    <v-form @submit.prevent="submit" ref="form" v-model="isFormValid">

        <v-card outlined :loading="isLoading">

            <v-card-title>
                <div class="font-weight-light display-1 width--full">
                    <div class="grey--text text--darken-3">{{ stepper.step }}. Submit your readings</div>
                    <v-divider class="mt-1"></v-divider>
                </div>
            </v-card-title>

            <v-card-text class="py-0">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            Please provide us with contact info to better assist you in regards to this meter reading
                            submission.
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="5" md="4" class="py-0">
                            <v-text-field v-model="getModel.submitter.contact_phone"
                                         v-mask="'###-###-####'"
                                          v-validate="'required|phoneNumber'"
                                          :error-messages="errors.collect('phone_number')"
                                          data-vv-name="phone_number"
                                          data-vv-as="Phone Number"
                                          data-vv-validate-on="blur"
                                          prepend-inner-icon="phone"
                                          hint="Example: 575-555-5555"
                                          maxlength="12"
                                          type="tel"
                                          clearable outlined>
                                <template v-slot:label>
                                    <div>Phone Number*</div>
                                </template>
                            </v-text-field>
                        </v-col>

                        <v-col cols="12" sm="5" md="4" class="py-0">
                            <v-text-field v-model="getModel.submitter.contact_email"
                                          :error-messages="errors.collect('email_address')"
                                          v-validate="'email'"
                                          data-vv-name="email_address"
                                          data-vv-as="Email address"
                                          data-vv-validate-on="blur"
                                          prepend-inner-icon="email"
                                          clearable outlined>
                                <template v-slot:label>
                                    <div>Email Address<small> (optional)</small></div>
                                </template>
                            </v-text-field>
                        </v-col>

                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            Which State Engineer's field office do you conduct meter reading business with?
                        </v-col>
                    </v-row>

                    <v-row>

                        <v-col cols="12" sm="5" md="4" class="py-0">
                            <v-select
                                    key="model.submitter.dist_office"
                                    name="dist_office"
                                    class="v-label--offset"
                                    v-model="getModel.submitter.dist_office"
                                    :items="districts"
                                    prepend-inner-icon="mdi-office-building"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                    :error-messages="errors.collect('dist_office')"
                                    v-validate="'required'"
                                    data-vv-as="District Office"
                                    label="District Office*"
                                    outlined required></v-select>
                        </v-col>

                        <v-col cols="12" sm="5" md="4" class="d-flex flex-column align-center justify-start pt-0">
                            <v-icon large color="blue">mdi-map-marker</v-icon>
                            <a href="https://ose.maps.arcgis.com/apps/webappviewer/index.html?id=d23dbb0ae81546969522685766d818b2"
                               target="_blank" class="blue--text">District Office Map</a>
                        </v-col>

                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            By submitting your meter readings using this web application, you agree to the

                            <v-btn  text
                                    color="primary"
                                    @click.stop="terms = true,forceRerender()"
                            >Terms and conditions.
                            </v-btn>

                        </v-col>

                        <v-dialog
                                v-model="terms"
                        >
                            <v-card>
                                <v-card-title class="headline">Terms and Conditions</v-card-title>
<!--                                terms pdf, pdfKey increments to prevent caching-->
                                <pdf  src="./static/terms.pdf" style="max-height: 200px; overflow: auto" :key="pdfKey"></pdf>
                                <v-card-actions>
                                    <v-spacer></v-spacer>

                                    <v-btn
                                            color="green darken-1"
                                            text
                                            @click="terms = false"
                                    >
                                        Close
                                    </v-btn>
                                    <v-btn
                                            color="green darken-1"
                                            text
                                            @click="terms = false,getModel.submitter.agree_terms = true"
                                    >
                                        Accept
                                    </v-btn>

                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                        <v-col cols="12" sm="5" md="4" class="py-0">
                            <v-checkbox
                                    v-model="getModel.submitter.agree_terms"
                                    :value="true"
                                    :error-messages="errors.collect('agree_terms')"
                                    v-validate="'required'"
                                    data-vv-name="agree_terms"
                                    data-vv-as="Agree to terms"
                                    label="I agree to the terms*">
                            </v-checkbox>
                        </v-col>

                        <v-col cols="12" sm="5" md="4" class="py-0">
                            <v-text-field v-model="getModel.submitter.submitted_by"
                                          :error-messages="errors.collect('submitted_by')"
                                          v-validate="'required'"
                                          data-vv-name="submitted_by"
                                          prepend-inner-icon="mdi-account"
                                          data-vv-as="Name"
                                          maxlength="30"
                                          :counter="30"
                                          clearable
                                          label="Submitted By*">
                            </v-text-field>
                        </v-col>

                    </v-row>
                    <small>*indicates required field</small>
                </v-container>

            </v-card-text>

        </v-card>

        <help-component :help="showHelpDrawer"
                        @toggle-help="showHelpDrawer=!showHelpDrawer">
            Help for the submit reading section
        </help-component>

        <footer-component :class="{'v-card--loading, v-card--disabled': isLoading}"
                          :disableNext="!isFormValid"
                          @step-back="stepBack"
                          @toggle-help="showHelpDrawer=!showHelpDrawer">

            <template slot="next">
                <span>Submit</span>
                <v-icon medium>mdi-chevron-right</v-icon>
            </template>
        </footer-component>

        <dialog-component :show="showRequestErrorDialog" @close-dialog="showRequestErrorDialog = false">
            <template slot="title">Server Error</template>
            <template slot="body"><p>Sorry, the meter reading submission failed at this time. Please Retry.</p>
                <!--<p v-if="requestError">Error is: {{ requestError }}</p>-->
               If the problem persists, please contact the OSE Meter Reading support.
            </template>
        </dialog-component>

    </v-form>

</template>

<script>

    import axios from 'axios';
    import districtsMixin from 'mixins/districts.mixin'
    import FooterComponent from "./FooterComponent";
    import HelpComponent from "./HelpComponent";
    import DialogComponent from "./DialogComponent";
    import {Validator} from 'vee-validate';
    import pdf from 'vue-pdf';

    const phoneNumber = {
        getMessage: field => `Invalid ${field.toLowerCase()} input<br/>Please use format: ###-###-####`,
        validate (value) {
            return /^\d{3}-\d{3}-\d{4}$/.test(value)
        }
    };
    Validator.extend('phoneNumber', phoneNumber);

    export default {

        name: 'Submit-Reading',

        $_veeValidate: {
            validator: 'new'
        },

        mixins: [districtsMixin],

        components: {
            FooterComponent,
            DialogComponent,
            HelpComponent,
            pdf
        },

        props: {
            model: {
                type: Object,
                required: true
            },
            stepper: {
                type: Object,
                required: true
            }
        },
        computed: {
            getModel() {
                return this.model
            },
        },
        data: () => ({
            isFormValid: false,
            isLoading: false,
            showRequestErrorDialog: false,
            showHelpDrawer: false,
            terms: false,
            pdfKey: 0,
            requestError: ""
        }),

        methods: {
            // pdfkey increments on each click to ensure
                // current terms are displayed without caching.
            forceRerender() {
                this.pdfKey += 1;
            },

            submit: function () {

                this.$validator.validateAll().then(isValid => {
                    if (isValid) {
                        let localStepper = this.stepper
                        localStepper.validators[this.stepper.step] = this.$validator;
                        this.isLoading = true;
                        this.$emit("updateStep", localStepper);
                        const url = `${process.env.VUE_APP_MR_API_URI}/intakes/create`;
                        const payload = {
                            data : {
                                wr_district: this.model.submitter.dist_office,
                                submitted_by: this.model.submitter.submitted_by,
                                contact_phone: this.model.submitter.contact_phone,
                                contact_email: this.model.submitter.contact_email,
                                meters: [this.model.meter.selected], // array for now
                                search: {... this.model.search}
                            }
                        };

                        axios.post(url, payload)
                            .then(res => {
                                if (res.data.data) {
                                    let localModel = this.model
                                    let localStepper = this.stepper
                                    localModel.submission.submission_id = res.data.data.submission_id;
                                    localModel.submission.submitted_date = res.data.data.submission_date;
                                    this.$emit("updateModel", localModel);
                                    localStepper.step++;
                                    this.$emit("updateStep", localStepper);
                                }
                                else {

                                        //get a canned message with env variable for quota
                                    //TODO : verify how to better handle this? this is a simple demonstration.
                                    //TODO: Axios - does not get the error returned from the laravel controller
                                    //maybe have a validation handler - querying the submitted #num readings db before submit?
                                    //in IntakeController ( MRINTAKE) return code of 4xx can be sent so 421 was
                                    // a test error code.
                                    if ( res.data.message.includes('421')) {

                                        this.requestError = 'Number of readings per month for meter exceeded quota of 3, please remove readings and resubmit';
                                    }
                                    this.showRequestErrorDialog = true;

                                }
                            })
                            .catch(err => {
                                this.requestError = err.response;
                                this.showRequestErrorDialog = true;
                                console.log(err);
                            })
                            .finally(() => this.isLoading = false)
                    }
                });
            },

            stepBack() {
                let localStepper = this.stepper;
                localStepper.step--;
                this.$emit("updateStep", localStepper);
            }
        },
    }

</script>