import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import veeValidate from 'vee-validate';


Vue.use(veeValidate, {inject: false});
// As a plugin
import VueMask from 'v-mask'
Vue.use(VueMask);
Vue.config.productionTip = false;

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app');
