<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">

    <v-form v-model="isFormValid">
        <v-card :ripple="false">

            <v-img height="110"
                   max-height="110"
                   src="../../public/meter-banner.jpg">
                <v-card-title
                        class="title trunc-cap fill-height d-flex justify-space-between pa-2 overlay--darken">
                    <v-list-item class="pa-0">
                        <v-list-item-content class="pa-0">
                            <!-- suffix consists of five spaces when null.  v-if handling for hyphens substitutes spaces and checks for empty string    -->
                            <v-list-item-title class="headline mb-0 yellow--text text--lighten-1">
                                Serial #: {{  model.meter.selected.meter_serial_number | emptyFormatter }}
                            </v-list-item-title>
                            <div class="small-overline ma-0 yellow--text text--lighten-1">Make: {{ model.meter.selected.meter_make }} </div>
                            <div v-if="model.pods.selected.suffix && model.pods.selected.suffix.replace(/\s/g, '').length > 0" class="small-overline ma-0 white--text">
                                Water Right: {{  model.pods.selected.basin}}- {{model.pods.selected.nbr}}- {{ model.pods.selected.suffix}}
                            </div>
                            <div v-else class="small-overline ma-0 white--text">
                                Water Right: {{  model.pods.selected.basin}}- {{model.pods.selected.nbr}} {{ model.pods.selected.suffix}}
                            </div>
                            <div  v-if="model.pods.selected.pod_suffix && model.pods.selected.pod_suffix.replace(/\s/g, '').length > 0" class="small-overline ma-0 white--text">
                                POD #: {{  model.pods.selected.pod_basin}}- {{model.pods.selected.pod_nbr}}- {{ model.pods.selected.pod_suffix}}
                            </div>
                            <div  v-else class="small-overline ma-0 white--text">
                                POD #: {{  model.pods.selected.pod_basin}}- {{model.pods.selected.pod_nbr}} {{ model.pods.selected.pod_suffix}}
                            </div>
                            <div class="small-overline ma-0 white--text">Meter Rec#: {{ model.meter.selected.meter_rec_number}}</div>
                        </v-list-item-content>
                    </v-list-item>
                </v-card-title>
            </v-img>

            <v-card-text class="pa-0">
                <v-container>

                    <v-list two-line subheader class="pa-0 mb-4" dense>
                        <v-btn :loading="isLoading" v-if="isLoading===true"
                               color="primary">
                            <span>Loading...</span>
                            <v-icon medium>mdi-restart</v-icon>
                        </v-btn>
                        <v-list-item v-if="model.meter.selected.readings.length === 0"
                                     class="pa-0">
                            <v-list-item-avatar>
                                <v-icon class="grey lighten-2 black--text">mdi-alert-circle-outline</v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content class="py-0">
                                <div class="text--primary">
                                    <template v-if="latestSubmittedReading">
                                        Enter one or more readings with dates after
                                        <strong> {{ latestSubmittedReadingDate }}</strong>

                                        with a actual reading greater than or equal to

                                        <strong>{{ latestSubmittedReading }}</strong>
                                        <i> unless you are reporting a rollover.</i>
                                        <v-tooltip top color="black white--text">
                                            <template v-slot:activator="{ on }">
                                            <span v-on="on">
                                                <v-icon small class="black--text ml-1 mt-n-01">mdi-help-circle</v-icon>
                                            </span>
                                            </template>
                                            <span>
                                            If the current meter reading is less than the one before it, a rollover has occurred.
                                            <br/>
                                            If adding a meter reading that is less than {{ latestSubmittedReading }},
                                            you will be prompted to specify if it's a rollover.
                                        </span>
                                        </v-tooltip>
                                    </template>
                                    <template v-else>
                                        Please enter one or more meter readings.
                                    </template>
                                </div>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item class="pa-0"
                                     v-for="(r, rIndex) in model.meter.selected.readings"
                                     :key="rIndex">

                            <v-list-item-avatar>
                                <v-icon class="blue-grey white--text">mdi-calendar-check</v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content class="py-0">
                                <v-list-item-title v-text="r.meter_reading_date"></v-list-item-title>
                                <v-list-item-subtitle class="text--primary">
                                    Reading: {{r.meter_reading}}<span v-if="r.is_rollover"
                                                                      class="red--text">*</span>
                                    <div v-if="r.is_rollover" class="red--text">*marked as rollover</div>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-text="r.comments"></v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action @click="editEntry(rIndex)" class="ma-0">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on">
                                            <v-icon class="blue-grey--text">mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>edit entry</span>
                                </v-tooltip>
                            </v-list-item-action>
                            <v-list-item-action @click="deleteEntry(rIndex)" class="ma-0">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on">
                                            <v-icon color="red darken-2">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>delete entry</span>
                                </v-tooltip>
                            </v-list-item-action>

                        </v-list-item>
                    </v-list>

                    <v-row>
                        <v-col cols="12" sm="6" class="py-0">

                            <v-dialog
                                    ref="dialog"
                                    v-model="showDateModal"
                                    :return-value.sync="activeEntry.meter_reading_date"
                                    persistent
                                    full-width
                                    width="290px">

                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            label="Reading Date*"
                                            class="v-label--offset"
                                            v-model="activeEntry.meter_reading_date"
                                            :error-messages="errors.collect('reading_date')"
                                            v-validate="isDirtyForm ? 'required' : ''"
                                            :data-vv-name="'reading_date'"
                                            data-vv-as="Meter Reading Date"
                                            prepend-inner-icon="event"
                                            :hint="`Select a date after ${getPreviousEntryDate()}`"
                                            persistent-hint clearable readonly outlined
                                            data-vv-validate-on="blur|change"
                                            v-on="on">
                                    </v-text-field>
                                    <v-alert
                                            v-model="showSetSubmissionLimitAlert"
                                            id="submissionLimitAlert"
                                            border="left"
                                            close-text="Close Alert"
                                            color="red accent-4"
                                            dark
                                            dismissible
                                    >
                                        Limit of 3 readings per month
                                    </v-alert>
                                </template>
                                <v-date-picker v-model="activeEntry.meter_reading_date"
                                               :min="setISODate(getPreviousEntryDate(activeEntry.index))"
                                               :max="setISODate(getNextEntryDate(activeEntry.index))"
                                               scrollable
                                               data-vv-validate-on="blur|change">
                                    <div class="flex-grow-1"></div>
                                    <v-btn text color="primary" @click="showDateModal = false">Cancel</v-btn>
                                    <v-btn text color="primary"
                                           @click="validateAndSave(activeEntry.meter_reading_date)">
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-dialog>
                        </v-col>
                        <v-col cols="12" sm="6" class="py-0">
                            <v-text-field type="number"
                                    label="Actual Reading*"
                                    v-model="activeEntry.meter_reading"
                                    :error-messages="errors.collect('meter_reading')"
                                    v-validate="isDirtyForm ? `required|decimal:3|max_value: ${getMaxReadingEntryValue()}` : ''"
                                    data-vv-name="meter_reading"
                                    data-vv-as="Actual Reading"
                                    :maxlength="parseInt(model.meter.selected.number_of_dials) + 4"
                                    :minlength="parseInt(model.meter.selected.number_of_dials) + 4"
                                    persistent-hint clearable outlined
                                    data-vv-validate-on="blur|change|input"
                                    :hint="`Max reading allowed is ${getMaxMeterReadingValue()} based on ${getNumOfDials()} dials. Do not include any multiplier in your reading`">
                            </v-text-field>
                            <v-alert
                                    v-model="showSetPreviousRolloverAlert"
                                    id="previousRolloverEntryExistsAlert"
                                    border="left"
                                    close-text="Close Alert"
                                    color="red accent-4"
                                    dark
                                    dismissible
                            >
                                Limit of 1 rollover reading per session
                            </v-alert>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" xs="12" class="py-0">
                            <v-text-field
                                    v-model="activeEntry.comments"
                                    maxlength="30"
                                    :counter="30"
                                    rows="1"
                                    prepend-inner-icon="comment"
                                    no-resize clearable outlined>
                                <template v-slot:label>
                                    <div>
                                        Comments<small> (optional)</small>
                                    </div>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>

                    <v-card-actions>
                        <v-btn @click="resetFormState" :disabled="!isDirtyForm" text>
                            Reset Form
                        </v-btn>
                        <v-btn @click="addEntry()" :disabled="!isFormValid || !isDirtyForm" outlined
                               color="primary">
                            <v-icon small>
                                {{ activeEntry.index !== null ? 'mdi-pencil' : 'mdi-plus'}}
                            </v-icon>
                            {{ activeEntry.index !== null ? 'update reading' : 'add reading'}}
                        </v-btn>
                    </v-card-actions>
                </v-container>
            </v-card-text>
        </v-card>

        <dialog-component :show="showSetRolloverDialog" :icon="'mdi-alert'" :type="'error'">

            <template slot="title">Meter Rollover</template>

            <template slot="body">
                <p>
                    The actual reading you are trying to enter is less than the one before. This usually happens if the
                    meter has reached its maximum value and reverts back to a zero reading.
                </p>
                <p>
                    Please choose cancel to correct your reading entry or proceed with your actual reading
                    by checking the "Verified As Rollover" option to confirm.
                </p>
                <v-row>
                    <v-col cols="12" class="py-0">
                        <v-checkbox
                                v-model="activeEntry.is_rollover"
                                :error-messages="errors.collect('is_rollover')"
                                v-validate="'required'"
                                data-vv-name="is_rollover"
                                data-vv-as="Rollover Entry"
                                label="Verified As Rollover*">
                        </v-checkbox>
                    </v-col>
                </v-row>
            </template>

            <template slot="actions">
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="cancelRolloverAction">
                    <span>Cancel</span>
                </v-btn>
                <v-btn :disabled="!activeEntry.is_rollover" color="primary" outlined @click="setRolloverAction">
                    <span>Continue</span>
                    <v-icon medium>mdi-chevron-right</v-icon>
                </v-btn>
            </template>

        </dialog-component>

        <dialog-component :show="showDeleteRolloverDialog" :icon="'mdi-delete'" :type="'error'">

            <template slot="title">Delete Rollover</template>

            <template slot="body">
                <p>
                    Deleting a rollover entry will <strong><u>delete all subsequent readings</u></strong>
                    entered after the rollover date.
                </p>
                <p>
                    Are you sure you want to proceed with deleting this rollover?
                </p>
            </template>

            <template slot="actions">
                <v-spacer></v-spacer>
                <v-btn text @click="showDeleteRolloverDialog = !showDeleteRolloverDialog">
                    <span>Cancel</span>
                </v-btn>
                <v-btn color="red" outlined @click="deleteRolloverAction(activeEntry.index)">
                    <span>Delete Rollover</span>
                </v-btn>
            </template>

        </dialog-component>

    </v-form>

</template>

<script>

    import axios from 'axios';
    import moment from 'moment';
    import DialogComponent from "./DialogComponent";

    export default {

        name: 'Reading-Detail-Component',

        components: {DialogComponent},

        props: {
            model: {
                type: Object,
                required: true
            },
            stepper: {
                type: Object,
                required:true
            }
        },

        watch: {
            meterSelected: {
                handler() { // reset entry form (if navigated back to meter list and selected different meter)
                    this.resetFormState();

                }
            },

            currentStep: {
                handler() {
                    //NOTE: this is step 3 - if this changes make sure to modify this
                    if (this.stepper.step === 3 ) {
                        this.getLatestReading();
                    }
                }
            },

            // : function (val) {
            //     this.fullName = val + ' ' + this.lastName
            // },


        },

        inject: ['$validator'],

        computed: {
            isDirtyForm() {
                return this.activeEntry.meter_reading_date !== ''
                    || this.activeEntry.meter_reading !== ''
                    || this.activeEntry.comments !== '';
            },
            currentStep() {
                return this.stepper.step;
            },
            meterSelected() {
                return this.model.meter.selected;
            },

            latestSubmittedReadingDate() {
                return this.usingSubmittedData ? this.submittedDate : this.model.meter.selected.current_reading_date;
            },
            latestSubmittedReading() {
                return this.usingSubmittedData ? this.submittedReading : this.model.meter.selected.current_reading;
            }

        },

        data: () => ({

            isFormValid: false,
            showDateModal: false,
            showSetRolloverDialog: false,
            showDeleteRolloverDialog: false,
            showSetSubmissionLimitAlert: false,

            activeEntry: {
                index: null,
                meter_reading_date: '',
                meter_reading: '',
                comments: '',
                is_rollover: false,
            },
            submittedDate : null,
            submittedReading: null,
            usingSubmittedData: false,
            receivedSubmitted: false,
            isLoading: false,
            submissionsForMonthCount: null,
            MONTHLY_SUBMISSION_LIMIT: 3,
            previousRolloverIndex: null, //-1 also indicates no previousRollover
            showSetPreviousRolloverAlert: false
        }),

        methods: {
              addEntry(){
                let isValid;
                this.showSetPreviousRolloverAlert = false;
                this.setPreviousRolloverEntry();
                this.$validator.validateAll().then(isValid = async() => {
                    if (isValid) {

                        let index = this.activeEntry.index; // convenience var

                        /*
                        prompt the user if the rollover is intentional.
                        If so, flag the entry as a retry and call addEntry again.
                        index is null for new records, index is populated for edited records
                        */
                        if (index != null) {
                            if(this.activeEntry.meter_reading < this.getPreviousEntryReading(index)
                                && !this.activeEntry.is_rollover){
                                this.showSetRolloverDialog = true
                                return;
                            }
                        }else if (this.isEntryLessThanPrevious(index) && !this.isRolloverFlagSet(index)) {
                            //prevent dialog if form is not valid
                            if( this.activeEntry.meter_reading &&  this.activeEntry.meter_reading !== "" )
                                this.showSetRolloverDialog = true;
                            return;
                        }

                        //remove rollover flag if edited to be greater than previous entry and remove subsequent entries
                        if(index != null) {
                            if (this.isEntryRollover(index) && !this.isEntryLessThanPrevious(index, true)) {
                                this.activeEntry.is_rollover = false;
                                this.getEntry(index).is_rollover = false;
                                console.log( 'Edited Rollover: deleting subsequent readings');
                                this.deleteSusequentEntries(index)
                            }
                        }

                        //limit of one rollover per submission
                        if(this.isEntryRollover(index) || this.isEntryLessThanPrevious(index, true)){
                             //needed for rollover check on next entry
                             //if statement allows edit on same rollover entry.
                             if(this.previousRolloverIndex != null && this.previousRolloverIndex >= 0 &&
                                 this.previousRolloverIndex !== index) {
                                     this.activeEntry.is_rollover = false;
                                 this.showSetPreviousRolloverAlert = true;
                                 console.log("This actoin would create multiple rollovers");
                                 return;
                             }
                             //delele sebsequent rollover that will be created
                            console.log("Checking for potential rollovers");
                             if(index != null && this.getEntriesCount()-1 > index && this.isNextEntryLessThanSelf(index)){
                                 console.log("This actoin would create multiple rollovers.  Deleting subsequent reading");
                                 this.deleteSusequentEntries(index)
                             }
                        }

                        //Check if submissions for month has been exceeded
                        await this.isOverMonthlySubmissionLimit( this.activeEntry.meter_reading_date);


                        if (this.isNewEntry(index)) {
                            this.getAllEntries().push({...this.activeEntry});

                        } else {
                            this.getAllEntries()[index] = {...this.activeEntry};
                        }
                        this.sortEntries();
                        this.resetFormState();
                    }
                });
            },

            editEntry(index) {
                this.activeEntry = {...this.getEntry(index)}; // shallow copy
                this.activeEntry.index = index;
            },

            deleteEntry(index) {
                this.activeEntry.index = index;

                if (this.isEntryRollover(index) && !this.isLastEntry(index)) {
                    this.showDeleteRolloverDialog = true;
                    return;
                }
                this.getAllEntries().splice(index, 1);
                this.activeEntry.index = null;
            },

            deleteSusequentEntries(index) {
                for (let i = index.valueOf(); i < this.getAllEntries().length -1; i++) {
                    this.deleteEntry(++i);
                }
            },

            setRolloverAction() {
                this.activeEntry.is_rollover = true;
                this.showSetRolloverDialog = false;
                this.addEntry();
            },

            cancelRolloverAction() {
                this.activeEntry.is_rollover = false;
                this.showSetRolloverDialog = false;
            },

            deleteRolloverAction(index) {
                this.getAllEntries().length = index;
                this.activeEntry.index = null;
                this.showDeleteRolloverDialog = false;
            },

            getAllEntries() {
                return this.model.meter.selected.readings;
            },

            sortEntries() {
                this.getAllEntries()
                    .sort((r1, r2) =>
                        new Date(r1.meter_reading_date) - new Date(r2.meter_reading_date));
            },

            getEntry(index) {
                return this.getAllEntries()[index];
            },

            getNextEntry(index) {
                return this.model.meter.selected.readings[index + 1];
            },

            getLastEntry() {
                return this.getEntry(this.getEntriesCount() - 1);
            },

            getEntriesCount() {
                return this.model.meter.selected.readings.length;
            },

            isEntryLessThanPrevious(index, ignoreRollover) {
                //  This method relies on activeEntry, and may encounter null index
                if(ignoreRollover){
                    return this.activeEntry.meter_reading < this.getPreviousEntryReading(index)
                }else {
                    return this.activeEntry.meter_reading < this.getPreviousEntryReading(index)
                        && this.isEntryRollover(index) !== true;
                }
            },

            isNextEntryLessThanSelf(index) {
                return this.activeEntry.meter_reading > parseFloat(this.getEntry(index +1)['meter_reading'])
            },

            isEntryRollover(index) {
                return this.isNewEntry(index) ?
                    this.activeEntry.is_rollover :
                    this.getEntry(index)['is_rollover'];
            },

            /*
             Check if the current entry being submitted (index +1)
             has is_rollover property as true.  Rollover dialog
             calls addEntry, thus increasing the index by 1 when this code
             is called for the second time.
             Editing produces a unique situation in which index and count cannot be compared.
             @param int index last added reading, not current readning
             @return boolean
             */
            isRolloverFlagSet(index) {
                //hanlde edits
                if(index !== null && this.getEntry(index)['is_rollover']){
                    return true
                }
                if(index !== null && index === this.getEntriesCount() -1){
                    return false
                }
                return index !== null
                    && index !== this.getEntriesCount()
                    && this.getNextEntry(index)['is_rollover']
            },

            isFirstAndOnlyEntry(index) {
                return index === null && !this.hasEntries();
            },

            isLastEntry(index) {
                return index === null || index === this.getEntriesCount() - 1;
            },

            isNewEntry(index) {
                return index === null;
            },

            hasEntries() {
                return this.getEntriesCount() > 0;
            },

            /**
             * Returns date of previous entry.
             *  If this is the first entry, the last date in waters will be returned
             *  New entries usually have an index of null. Therefore, it will be
             *     assumened that null index is the last entry.
             *
             * @param {number} index the index of the active entry.
             * @return {date} The date of previous entry.
             */
            getPreviousEntryDate(index) {
                if(index == null) {
                    index = this.getAllEntries().length
                }
                return (index != null && index > 0 && this.getEntry(index - 1)) ?
                    this.getEntry(index -1)['meter_reading_date'] :
                    this.getWATERSCurrReadingDate()
            },

            /**
             * Returns the date of next entry.
             *  If the active record is not the last record:
             *       The day before the date of the next record is returned
             *  If this is the last record, a new date is returned.
             *
             * @param {number} index the index of the active entry.
             * @return {date} new date or The day before the next entry.
             */
            getNextEntryDate(index) {
                return (index != null && this.getEntry(index + 1)) ?
                    new Date(this.getEntry(index + 1)['meter_reading_date']).setDate(new Date(this.getEntry(index + 1)['meter_reading_date']).getDate() - 1) :
                    new Date()
            },

            getWATERSCurrReadingDate() {
                 return ( this.usingSubmittedData ? this.submittedDate : this.model.meter.selected.current_reading_date);

            },

            getWATERSCurrReading() {
               return ( this.usingSubmittedData ? parseFloat(this.submittedReading) : parseFloat(this.model.meter.selected.current_reading));


            },

            getLastEntryReading(index) {
                if (this.isFirstAndOnlyEntry(index)) // get waters
                    return this.getWATERSCurrReading();

                if (!this.isLastEntry(index)) // get next
                    return parseFloat(this.getNextEntry(index)['meter_reading']);

                if (this.isLastEntry(index)) // get last
                    return parseFloat(this.getLastEntry(index)['meter_reading']);
            },

            getPreviousEntryReading(index) {
                if (this.isFirstAndOnlyEntry(index) || index === 0) { // get waters
                    return this.getWATERSCurrReading();
                }else if(index != null) {
                    return parseFloat(this.getEntry(index -1)['meter_reading']);
                }else{
                    return parseFloat(this.getLastEntry()['meter_reading']);
                }
            },

            setISODate(date) {
                return new Date(date).toISOString();
            },

            getNumOfDials() {
                return this.model.meter.selected.number_of_dials;
            },

            getMaxReadingEntryValue() {
                let index = this.activeEntry.index;
                if (this.isNewEntry(index) || this.isLastEntry(index) || this.isRolloverFlagSet(index)) {
                    return this.getMaxMeterReadingValue();
                }
                return this.getLastEntryReading(index);
            },

            getMaxMeterReadingValue() {
                return Math.pow(10, this.getNumOfDials()) - .001;
            },

            resetFormState() {
                this.activeEntry.meter_reading_date = '';
                this.activeEntry.meter_reading = '';
                this.activeEntry.comments = '';
                this.activeEntry.is_rollover = false;
                this.activeEntry.index = null;
                this.$validator.reset();
                this.isLoading = false;
                this.receivedSubmitted = false;
                this.usingSubmittedData = false;
                this.showSetPreviousRolloverAlert = false;
            },

            //get the reading from submitted db

            //add method to get last submitted reading from webapp
            getLatestReading() {

                if ( this.model.meter.selected && this.model.meter.selected.meter_rec_number) {
                    //initialize the data with selected meter's waters reading data
                    this.submittedDate = this.model.meter.selected.current_reading_date;
                    this.submittedReading = this.model.meter.selected.current_reading;
                    this.isLoading  = true;

                const url = `${process.env.VUE_APP_MR_API_URI}/intakes/latest/${this.model.meter.selected.meter_rec_number}`;

                     axios.get(url).then(res => {
                            this.receivedSubmitted = true;
                            this.isLoading = false;
                            //did we get an array with items
                        if ( res.data.data && res.data.data['meter_reading_date'] ) {

                                try {
                                    let selectedMeter = this.model.meter.selected;
                                    this.submittedReading = res.data.data['meter_reading'];
                                    let dtSubmitted = Date.parse(res.data.data['meter_reading_date']);
                                    this.submittedDate = moment(dtSubmitted).format('YYYY-MM-DD');//important for datepicker
                                    let dtMeterReading = Date.parse(selectedMeter.current_reading_date);

                                    if (   ( dtSubmitted > dtMeterReading ) )  {
                                        //set the meter current info directly
                                        console.log( 'setting the submitted reading date',this.submittedDate);
                                        this.usingSubmittedData = true;
                                    }
                                }
                                catch(err) {
                                    console.log('Date conversion error ', err);
                                }


                            }
                        })
                        .catch(err => {
                            console.log( "Error in Querying for Meter Submitted Readings",err);
                        })
                     .finally(() => this.isLoading = false)
                }

            }, //getLatestReading

            /*
            Retrieve the number of readings added to local application for a
            given month.
            @param (stinng month(1-12), string(year 'YYYY'))
            @return integer
            */
            getAddedReadingsByMonthCount(month,year){
                let theCount = 0;
                this.getAllEntries().forEach((entry) => {
                    let [entryMonth, entryYear] = this.getMonthYear(entry.meter_reading_date);
                    if(month === entryMonth && year === entryYear) {theCount++}
                });
                return theCount
            },

            /*
            Retrieve the number of readings submitted for a given month.
            Service to MRAPI and forwarded to MRINTAKE
            Data from SQL_Server and Informix DB
            @param string of date 'YYYY-MM-DD'
            @return integer
            */
            getSubmissionsForMonthCount(activeDate) {
                 if(!activeDate){
                    return true
                }
                let [month,year] = this.getMonthYear(activeDate);
                if ( this.model.meter.selected && this.model.meter.selected.meter_rec_number) {
                    this.isLoading  = true;
                    const countUrl = `${process.env.VUE_APP_MR_API_URI}/intakes/readings_count/${this.model.meter.selected.meter_rec_number}/month/${month}/year/${year}`;
                    return axios.get(countUrl)
                        .catch(err => {
                            console.log( "Error in Querying for Monthly Submissions",err);
                        })
                        .finally(() => this.isLoading = false)
                }
            }, //getSubmissionsForMonthCount

            /*
            Check if the submission count for the month is over
            MONTHLY_SUBMISSION_LIMIT.  If so, delete the
            reading by index (etriesCount -1) and display the v-alert
            @param string of date 'YYYY-MM-DD'
            @return boolean --currently not used due to sync / async issues in addEntry/Validation
            @call deleteEntry(index) --if over limit
            */
            isOverMonthlySubmissionLimit(activeDate) {
                this.getSubmissionsForMonthCount(activeDate).then(res => {
                    this.showSetSubmissionLimitAlert = false;
                    if ( res.data.data && res.data.data['count'] ) {
                        let [month,year] = this.getMonthYear(activeDate);
                        this.submissionsForMonthCount = parseInt(res.data.data['count']) + parseInt( this.getAddedReadingsByMonthCount(month,year));
                        if(this.submissionsForMonthCount > this.MONTHLY_SUBMISSION_LIMIT){
                            this.showSetSubmissionLimitAlert = true;
                            //TODO: this can be handled more gracefully in the parent.
                            this.deleteEntry(this.getEntriesCount() -1 );
                            return true
                        }
                    }
                    return false
                })
            },

            /*
            Convert the string 'YYYY-MM-DD' to month (1-12) and year (yyyy)
            @param string of date 'YYYY-MM-DD', int index of reading
            @return String month (01-12), String year (YYYY)
            */
            getMonthYear(activeDate){
                let formattedDate = moment(activeDate,'YYYY-MM-DD').toDate();
                let month = formattedDate.getMonth() + 1; //submitted month should be 1-12 not 0-11
                let year = formattedDate.getFullYear();
                return([month,year])
            },

            setPreviousRolloverEntry(){
                this.previousRolloverIndex = this.getAllEntries().findIndex(element => element.is_rollover);
            },


            /*
            save item and validate all form objects
            @param user input on form object requesting save
            */
            validateAndSave(inputValue){
                this.$refs.dialog.save(inputValue);
                this.$validator.validateAll()
            },
        },
    }
</script>
