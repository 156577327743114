<template>

    <div>
        <v-card outlined>

            <v-card-title>
                <div class="font-weight-light display-1 width--full">
                    <div class="grey--text text--darken-3">Thank You!</div>
                    <v-divider class="mt-1"></v-divider>
                </div>
            </v-card-title>

            <v-card-text>

                <v-container fluid>
                    <v-row justify="center">
                        <v-col cols="12">

                            <h2 class="font-weight-regular mb-4">
                                <v-icon color="primary" large>mdi-checkbox-marked-circle-outline</v-icon>
                                We have received your meter reading submission.
                            </h2>

                            <div class="ml-10">
                                <h3 class="font-weight-regular mb-2">Confirmation number is:
                                    <span class="font-weight-bold">{{ model.submission.submission_id }}</span>
                                </h3>
                                <p>For your records, download a copy of your meter reading submission.</p>

                                <v-btn @click="download"
                                       color="orange" dark large>
                                    <v-icon medium class="mr-1">mdi-cloud-download-outline</v-icon>
                                    <span>Download PDF</span>
                                </v-btn>
                            </div>

                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>

        <footer-component :class="{'v-card--loading, v-card--disabled': isLoading}"
                          :back="false"
                          :help="false"
                          :next="false">

            <template slot="content">
                <div class="d-flex width--full justify-end">
                    <div>
                    <div class="d-flex justify-space-around px-4">
                        <v-btn :loading="isLoading" class="mr-4"
                               color="primary" outlined large
                               @click="meterList">
                            <span>Meter List</span>
                            <v-icon medium>mdi-restart</v-icon>
                        </v-btn>

                        <v-btn :loading="isLoading"
                               color="primary" large
                               @click="searchNew">
                            <span>Search New</span>
                            <v-icon medium>mdi-restart</v-icon>
                        </v-btn>
                    </div>
                    </div>
                </div>
            </template>
        </footer-component>

        <dialog-component :show="showRequestErrorDialog" @close-dialog="showRequestErrorDialog = false">
            <template slot="title">Server Error</template>
            <template slot="body">Sorry, downloading the PDF failed at this time.<p/>
                Please try again. If the problem persists, please contact the OSE Meter Reading support.
            </template>
        </dialog-component>

    </div>

</template>

<script>

    import axios from 'axios';
    import FooterComponent from "./FooterComponent";
    import DialogComponent from "./DialogComponent";

    export default {

        components: {FooterComponent, DialogComponent},

        props: {
            model: {
                type: Object,
                required: true
            },
            stepper: {
                type: Object,
                required: true
            }
        },

        data: () => ({
            isLoading: false,
            showHelpDrawer: false,
            showRequestErrorDialog: false,
        }),

        methods: {

            searchNew() {
                this.clearReadings();
                let localModel = this.model
                localModel.search.basin = '';
                localModel.search.suffix = '';
                localModel.search.wr_nbr = '';
                localModel.search.well_tag_nbr = '';
                localModel.submitter.agree_terms = null;
                this.$emit("updateModel", localModel);

                this.$nextTick(() => {
                    let localStepper = this.stepper
                    this.clearAllValidators();
                    localStepper.step = 1;
                    this.$emit("updateStepper", localStepper);
                });
            },

            meterList() {
                this.clearReadings();
                let localModel = this.model
                localModel.submitter.agree_terms = null;
                this.$emit("updateModel", localModel);

                this.$nextTick(() => {
                    this.clearAllValidators();
                    let localStepper = this.stepper
                    localStepper.step = 2
                    this.$emit("updateStepper", localStepper);
                });
            },

            clearReadings() {
                let localModel = this.model
                localModel.pods.list.map(pod => pod.meter.readings = []); // clear out all readings
                localModel.meter.selected.readings = [];
                this.$emit("updateModel", localModel);
            },

            clearAllValidators() {
                let localStepper = this.stepper
                localStepper.validators.forEach(validator => validator.reset());
                this.$emit("updateStepper", localStepper);
            },

            download() {

                let url = `${process.env.VUE_APP_MR_INTAKE_API_URI}/api/pdf/${this.model.submission.submission_id}/submission`;

                axios({
                    url: url,
                    method: 'POST',
                    responseType: 'blob'
                }).then(res => {

                    const pdfBlob = new Blob([res.data], {type: 'application/pdf'});
                    const fileName = `OSE Meter Reading Submission #${this.model.submission.submission_id}.pdf`;

                    if (window.navigator.msSaveBlob) { // IE 11
                        window.navigator.msSaveOrOpenBlob(pdfBlob, fileName);
                    } else {
                        const url = window.URL.createObjectURL(pdfBlob);
                        const anchor = document.createElement('a');
                        anchor.href = url;
                        anchor.download = fileName;
                        anchor.click();
                    }
                })
                    .catch(err => {
                        this.showRequestErrorDialog = true;
                        console.log(err);
                    });
            }
        }
    }

</script>