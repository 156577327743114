<template>

    <v-card ref="card" :ripple="ripple" :color="active ? 'primary' : ''" dark hover>

        <v-img class="white--text"
               height="110"
               max-height="110"
               src="../../public/meter-banner.jpg">

            <v-card-title
                    class="title text-uppercase text-truncate fill-height d-flex justify-space-between pa-2 overlay--darken">
                <!-- suffix consists of five spaces when null.  v-if handling for hyphens substitutes spaces and checks for empty string    -->
                <v-list-item class="pa-0">
                    <v-list-item-content class="pa-0">
                        <v-list-item-title class="headline mb-0 yellow--text text--lighten-1">
                            Serial#: {{ meter.meter_serial_number | emptyFormatter }}
                        </v-list-item-title>
                        <div class="small-overline ma-0 yellow--text text--lighten-1">Make: {{ meter.meter_make | noMake }} </div>
                        <div v-if="meter.suffix && meter.suffix.replace(/\s/g, '').length > 0" class="small-overline ma-0 white--text">
                            Water Right: {{meter.basin}}- {{meter.nbr}}- {{meter.suffix}}
                        </div>
                        <div v-else class="small-overline ma-0 white--text">
                            Water Right: {{meter.basin}}- {{meter.nbr}} {{meter.suffix}}
                        </div>
                        <div  v-if="meter.pod_suffix  && meter.pod_suffix.replace(/\s/g, '').length > 0" class="small-overline ma-0 white--text">
                            POD #: {{meter.pod_basin}}- {{meter.pod_nbr}}- {{meter.pod_suffix}}
                        </div>
                        <div  v-else class="small-overline ma-0 white--text">
                            POD #: {{meter.pod_basin}}- {{meter.pod_nbr}} {{meter.pod_suffix}}
                        </div>
                        <div class="small-overline ma-0 white--text">Meter Rec#: {{ meter.meter_rec_number}}</div>


                    </v-list-item-content>
                    <v-avatar :color="active ? 'primary' : ''">
                        <v-icon x-large>
                            {{ active ? 'mdi-check' : '' }}
                        </v-icon>
                    </v-avatar>
                </v-list-item>
            </v-card-title>

        </v-img>

        <v-card-text class="pa-2">
            <div class="text-truncate" :title="`${meter.current_reading} (${meter.current_reading_date})`">
                Last Reading:
                {{ meter.current_reading | emptyFormatter }}
            </div>
            <div class="text-truncate" :title="meter.number_of_dials">
                Number of Dials:
                {{ meter.number_of_dials | emptyFormatter }}
            </div>
            <div class="text-truncate" :title="meter.unit_of_measure">
                Unit of Measure:
                {{ meter.unit_of_measure | emptyFormatter  }}
            </div>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="doRipple(toggle, $event)" outlined class="px-4" min-width="135px" type="submit">
                <v-icon small class="mr-1">{{ active ? 'close' : 'check' }}</v-icon>
                {{ active ? 'Unselect' : 'Select'}}
            </v-btn>
            <v-spacer></v-spacer>
        </v-card-actions>

    </v-card>

</template>

<script>

    export default {

        name: 'Meter-Detail',

        props: {
            model: {
                type: Object,
                required: true
            },
            meter: {
                type: Object,
                required: true
            },
            active: {
                type: Boolean,
                required: true
            },
            toggle: {
                type: Function,
                required: true
            }
        },

        data: () => ({
            ripple: false
        }),

        methods: {

            doRipple(toggle, event) {

                this.ripple = !this.ripple;

                this.$nextTick(() => {
                    toggle();
                    let ev = new Event("mousedown");
                    ev.clientX = event.clientX;
                    ev.clientY = event.clientY;
                    this.$refs.card.$el.dispatchEvent(ev);

                    this.$nextTick(() => {
                        this.$refs.card.$el.dispatchEvent(new Event("mouseup"));
                        this.ripple = !this.ripple;
                    })
                });
            }
        }
    }

</script>